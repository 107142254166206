<template>
	<div class="georepreportFormHeader puiformheader">
		<v-layout wrap>
			<v-flex xs12 md12>
				<v-row dense>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="$t('header.georepreport.avesselname')" :value="getAvesselnameValue"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="$t('header.georepreport.georeptypedesc')" :value="getGeoreptypedescValue"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="$t('header.georepreport.arrdeptrans')" :value="getArrdeptransValue"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="$t('header.georepreport.uploaddate')" :value="getFormattedUploaddate"></pui-field>
					</v-col>
				</v-row>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'georepreport-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'georepreport'
		};
	},
	computed: {
		getAvesselnameValue() {
			return this.model && this.model.avesselname ? this.model.avesselname : '-';
		},
		getGeoreptypedescValue() {
			return this.model && this.model.georeptypedesc ? this.model.georeptypedesc : '-';
		},
		getArrdeptransValue() {
			return this.model && this.model.arrdeptrans ? this.model.arrdeptrans : '-';
		},
		getUploaddateValue() {
			return this.model && this.model.uploaddate ? this.model.uploaddate : '-';
		},
		getFormattedUploaddate() {
			if (this.model && this.model.uploaddate) {
				const uploadDate = new Date(this.model.uploaddate);
				const formattedDate = `${uploadDate.toLocaleDateString()} ${uploadDate.toLocaleTimeString()}`;
				return formattedDate;
			} else {
				return '-';
			}
		}
	}
};
</script>
